<template>
  <div class="submitPage">
    <el-input type="textarea" placeholder="请输入软件使用过程中遇到的问题，请不要留您的联系方式，我们不会主动联系您的，有任何问题请联系您的推荐人或者代理商！" v-model="textarea" class="submitTextarea">
    </el-input>
    <p class="submitBtn  colorWhite textCenter cursor" @click="submitMethod"><i class="el-icon-loading"
      v-if="isLoading"></i>提交</p>
  </div>
</template>
  <script>
import api from '../../http/api'
import { Message } from 'element-ui';
export default {
  data() {
    return {
      textarea: '',
      isLoading:false,//是否加载
    }
  },
  methods: {
    submitMethod() {
      let that = this;
      if (!that.textarea) {
        Message({
          message: `请输入您所需要的需求~`,
          type: 'error'
        });
        return;
      }
      that.isLoading=true;
      api.getSubmit({
        demand:that.textarea
      }).then(res=>{
        that.isLoading=false
        console.log('res',res)
        let isTrue=res.code==200&&res.response.success?true:false
        isTrue&&(that.textarea='')
        Message({
          message:`${isTrue?'需求提交成功~':res.msg}`,
          type: isTrue?'success':'error'
        });
      })
    }
  }
}
</script>
  <style lang="less" scoped>
  .submitPage {
    border: 1px solid #DCDCDC;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding: 18px 24px;
    overflow-x: hidden;
  
    .submitTextarea {
      width: 100%;
      height: calc(100% - 45px);
  
      /deep/ .el-textarea__inner {
        height: 100%;
        border: 0;
      }
    }
  
    .submitBtn {
  
      width: 130px;
      line-height: 40px;
      float: right;
      font-size: 18px;
      background-image: linear-gradient(#B1D9FF, #46A7FE);
    }
  }
  </style>
